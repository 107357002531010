import 'intl-tel-input/build/js/utils.js'
import intlTelInput from 'intl-tel-input'

/**
 * For fields with class `intl-tel-input`, initialize the intl-tel-input library.
 * Assumes
 *   - the field has an id of the form `phone_number_national`
 *   - there is a field with id `phone_number_validation` to display validation errors
 *   - you are expecting a field to submit with the name `phone_number` in E.164 format
 * this will
 *   - Add validation errors to the element with id `${iti.telInput.id}_validation`.
 *   - Add an error state to the field if the number is invalid.
 */
export default function initIntlTelInput () {
  document.querySelectorAll('.intl-tel-input').forEach((input) => {
    const iti = intlTelInput(input, {
      autoPlaceholder: 'aggressive',
      geoIpLookup (callback) {
        fetch('https://ipapi.co/json')
          .then((res) => res.json())
          .then((data) => { callback(data.country_code) })
          .catch(() => { callback('us') })
      },
      hiddenInput (telInputName) {
        return { phone: telInputName.replace('_national', '') }
      },
      initialCountry: 'auto', // does not update the country if an initial country is not set
      showSelectedDialCode: true,
      utilsScript: '@modules/intl-tel-input/build/js/utils.js',
    }),

     validationElem = document.getElementById(input.id.replace('_national', '_validation')),

     validate = () => {
      if (input.value.trim()) {
        if (iti.isValidNumber()) {
          input.classList.remove('form-field-error')
          validationElem.classList.add('hidden')
        } else {
          input.classList.add('form-field-error')
          validationElem.innerHTML = 'Invalid number'
          validationElem.classList.remove('hidden')
        }
      }
    }

    input.addEventListener('change', validate)
  })
}
